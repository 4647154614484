import React from "react"
import { Link } from "gatsby"

import { Layout } from "../../components/Layout"
import { SEO } from "../../components/SEO"

import "./404.scss"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 Not Found" />
    <section className="not-found">
      <h1>NOT FOUND</h1>
      <p>
        You just hit a route that doesn't exist. Click <Link to="/">here</Link>{" "}
        to go home.
      </p>
    </section>
  </Layout>
)

export default NotFoundPage
